// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=this.lambda, alias2=this.escapeExpression;

  return "  <tr>\n    <td>"
    + alias2(alias1((depth0 != null ? depth0.name : depth0), depth0))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.count : depth0),{"name":"if","hash":{},"fn":this.program(2, data, 0, blockParams, depths),"inverse":this.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    <td>"
    + alias2(alias1((depth0 != null ? depth0.source : depth0), depth0))
    + "</td>\n    <td>\n      <form method=\"post\">\n        <input id=\"csrf_token\" name=\"csrf_token\" type=\"hidden\" value=\""
    + alias2(((helper = (helper = helpers.csrf || (depth0 != null ? depth0.csrf : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"csrf","hash":{},"data":data}) : helper)))
    + "\">\n        <input id=\"id_\" name=\"id_\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? depth0.id : depth0), depth0))
    + "\">\n        <input id=\"permission\" name=\"permission\" type=\"hidden\" value=\""
    + alias2(alias1((depths[1] != null ? depths[1].name : depths[1]), depth0))
    + "\">\n        <input id=\"action\" name=\"action\" type=\"hidden\" value=\"delete\">\n        <input id=\"form_id\" name=\"form_id\" type=\"hidden\" value=\"entity\">\n        <button class=\"close\" type=\"submit\">&times;</button>\n      </form>\n  </tr>\n";
},"2":function(depth0,helpers,partials,data) {
    var alias1=this.escapeExpression;

  return "    <td>"
    + alias1((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"Group",{"name":"gettext","hash":{},"data":data}))
    + " ("
    + alias1(this.lambda((depth0 != null ? depth0.count : depth0), depth0))
    + ")</td>\n";
},"4":function(depth0,helpers,partials,data) {
    return "    <td>"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"User",{"name":"gettext","hash":{},"data":data}))
    + "</td>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<table class=\"table table-condensed\">\n  <tr>\n    <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Name",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n    <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Type",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n    <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Authentication Source",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n    <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Remove",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n  </tr>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.entities : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0, blockParams, depths),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "</table>\n";
},"useData":true,"useDepths":true});
