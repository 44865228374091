// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    return "";
},"3":function(depth0,helpers,partials,data) {
    var stack1;

  return " <small class=\"text-muted\">\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.sign : depth0),"-",{"name":"compare","hash":{},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(depth0,helpers,partials,data) {
    return this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"not",{"name":"gettext","hash":{},"data":data}))
    + "</small>\n";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.sign : depth0),"<",{"name":"compare","hash":{},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    return "\n"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"before",{"name":"gettext","hash":{},"data":data}))
    + "</small>\n";
},"9":function(depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.sign : depth0),">",{"name":"compare","hash":{},"fn":this.program(10, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"10":function(depth0,helpers,partials,data) {
    return "\n"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"after",{"name":"gettext","hash":{},"data":data}))
    + "</small>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing;

  return "<div class=\"option\">\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.sign : depth0),"=",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + this.escapeExpression(((helper = (helper = helpers.realValue || (depth0 != null ? depth0.realValue : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"realValue","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"useData":true});
