// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<div class=\"list-group-item\">\n"
    + ((stack1 = helpers["if"].call(depth0,(helpers.status_color || (depth0 && depth0.status_color) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),{"name":"status_color","hash":{},"data":data}),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "  <blockquote class=\"list-group-item-text\">\n    <p>"
    + alias2((helpers.urlize || (depth0 && depth0.urlize) || alias1).call(depth0,(depth0 != null ? depth0.note : depth0),{"name":"urlize","hash":{"limit":30},"data":data}))
    + "</p>\n    <footer>"
    + alias2(this.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + " ("
    + alias2((helpers.datefmt || (depth0 && depth0.datefmt) || alias1).call(depth0,(depth0 != null ? depth0.timestamp : depth0),{"name":"datefmt","hash":{},"data":data}))
    + ")</footer>\n  </blockquote>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
    var alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "  <h4 class=\"list-group-item-heading text-"
    + alias2((helpers.status_color || (depth0 && depth0.status_color) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),{"name":"status_color","hash":{},"data":data}))
    + "\">"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,(helpers.capitalize || (depth0 && depth0.capitalize) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),{"name":"capitalize","hash":{},"data":data}),{"name":"gettext","hash":{},"data":data}))
    + "</h4>\n";
},"4":function(depth0,helpers,partials,data) {
    return "  <h4 class=\"list-group-item-heading\"></h4>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.actions : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
