// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    return "";
},"3":function(depth0,helpers,partials,data) {
    var helper;

  return this.escapeExpression(((helper = (helper = helpers.sign || (depth0 != null ? depth0.sign : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"sign","hash":{},"data":data}) : helper)));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<div class=\"item\">\n"
    + alias2((helpers.attr_gettext || (depth0 && depth0.attr_gettext) || alias1).call(depth0,(depth0 != null ? depth0.attribute : depth0),{"name":"attr_gettext","hash":{},"data":data}))
    + ":"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.sign : depth0),"=",{"name":"compare","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + alias2(((helper = (helper = helpers.realValue || (depth0 != null ? depth0.realValue : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"realValue","hash":{},"data":data}) : helper)))
    + "\n</div>\n";
},"useData":true});
