// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    return this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"%(group_name)s(%(member_count)d) <small class=\"text-muted text-right\">from %(auth_source)s</small>",{"name":"gettext","hash":{"auth_source":(depth0 != null ? depth0.source : depth0),"member_count":(depth0 != null ? depth0.count : depth0),"group_name":(depth0 != null ? depth0.name : depth0)},"data":data}))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
    return this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"%(user_name)s <small class=\"text-muted text-right\">from %(auth_source)s</small>",{"name":"gettext","hash":{"auth_source":(depth0 != null ? depth0.source : depth0),"user_name":(depth0 != null ? depth0.name : depth0)},"data":data}))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"option\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.count : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
