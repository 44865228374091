// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    return "<div class=\"panel panel-danger\">\n";
},"3":function(depth0,helpers,partials,data) {
    return "<div class=\"panel panel-success\">\n";
},"5":function(depth0,helpers,partials,data) {
    var helper;

  return "    <div class=\"panel-body\">\n      "
    + this.escapeExpression(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0,{"name":"note","hash":{},"data":data}) : helper)))
    + "\n    </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.value : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":this.program(1, data, 0),"inverse":this.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "  <div class=\"panel-heading\" data-toggle=\"collapse\" data-target=\"#modifier"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <form class=\"form\" method=\"post\">\n      <input id=\"csrf_token\" name=\"csrf_token\" type=\"hidden\" value=\""
    + alias3(((helper = (helper = helpers.csrf || (depth0 != null ? depth0.csrf : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"csrf","hash":{},"data":data}) : helper)))
    + "\">\n      <input id=\"id_\" name=\"id_\" type=\"hidden\" value=\"void\">\n      <input id=\"modifier_id_"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" name=\"modifier_id\" type=\"hidden\" value=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n      <button class=\"close\" type=\"submit\" name=\"void_modifier\">&times;</button>\n    </form>\n    <h4 class=\"panel-title\">"
    + alias3((helpers.modifier_header || (depth0 && depth0.modifier_header) || alias1).call(depth0,depth0,{"name":"modifier_header","hash":{},"data":data}))
    + "</h4>\n  </div>\n  <div id=\"modifier"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"collapse in\">\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.note : depth0),{"name":"if","hash":{},"fn":this.program(5, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"panel-footer\">\n      "
    + alias3(this.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + " ("
    + alias3((helpers.datefmt || (depth0 && depth0.datefmt) || alias1).call(depth0,(depth0 != null ? depth0.timestamp : depth0),{"name":"datefmt","hash":{"style":"short"},"data":data}))
    + ")\n    </div>\n  </div>\n</div>\n";
},"useData":true});
