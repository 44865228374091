// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "<div class=\"panel panel-default\">\n  <div class=\"panel-heading\" data-toggle=\"collapse\" data-target=\"#modifier"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <del><h4 class=\"panel-title\">"
    + alias3((helpers.modifier_header || (depth0 && depth0.modifier_header) || alias1).call(depth0,depth0,{"name":"modifier_header","hash":{},"data":data}))
    + "</h4></del>\n  </div>\n  <div id=\"modifier"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"collapse\">\n    <div class=\"panel-body\">\n      <del>\n        "
    + alias3(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"note","hash":{},"data":data}) : helper)))
    + " "
    + alias3((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"voided by %(voided_name)s at %(voided_timestamp)s",{"name":"gettext","hash":{"voided_timestamp":(helpers.datefmt || (depth0 && depth0.datefmt) || alias1).call(depth0,((stack1 = (depth0 != null ? depth0["void"] : depth0)) != null ? stack1.timestamp : stack1),{"name":"datefmt","hash":{"style":"short"},"data":data}),"voided_name":((stack1 = ((stack1 = (depth0 != null ? depth0["void"] : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1)},"data":data}))
    + "\n      </del>\n    </div>\n    <div class=\"panel-footer\">\n      "
    + alias3(this.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + " ("
    + alias3((helpers.datefmt || (depth0 && depth0.datefmt) || alias1).call(depth0,(depth0 != null ? depth0.timestamp : depth0),{"name":"datefmt","hash":{"style":"short"},"data":data}))
    + ")\n    </div>\n  </div>\n</div>\n";
},"useData":true});
