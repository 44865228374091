// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "<div class=\"create\"><small class=\"text-muted\">"
    + alias2((helpers.attr_gettext || (depth0 && depth0.attr_gettext) || alias1).call(depth0,"details",{"name":"attr_gettext","hash":{},"data":data}))
    + "</small> "
    + alias2(((helper = (helper = helpers.input || (depth0 != null ? depth0.input : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"input","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"useData":true});
