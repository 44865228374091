// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    var stack1;

  return "        <dt>"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"Modifiers",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>\n          <a class=\"small-popover null-link modifiers\" data-toggle=\"popover\" data-trigger=\"focus\" data-content='\n            <ul>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.modifiers : depth0),{"name":"each","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "          </ul>' data-html=\"true\" data-container=\"body\" href=\"#\" tabindex=\"0\">Modifiers</a>\n        </dd>\n";
},"2":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0,(depth0 != null ? depth0["void"] : depth0),{"name":"unless","hash":{},"fn":this.program(3, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"3":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.value : depth0),0,{"name":"compare","hash":{"operator":">"},"fn":this.program(4, data, 0),"inverse":this.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + this.escapeExpression((helpers.modifier_header || (depth0 && depth0.modifier_header) || alias1).call(depth0,depth0,{"name":"modifier_header","hash":{},"data":data}))
    + "</li>";
},"4":function(depth0,helpers,partials,data) {
    return "<li class=\"text-success\">";
},"6":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.value : depth0),0,{"name":"compare","hash":{"operator":"<"},"fn":this.program(7, data, 0),"inverse":this.program(9, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(depth0,helpers,partials,data) {
    return "<li class=\"text-danger\">";
},"9":function(depth0,helpers,partials,data) {
    return "<li>";
},"11":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2="function", alias3=this.escapeExpression;

  return "          <form method=\"post\" action=\""
    + alias3(((helper = (helper = helpers.href || (depth0 != null ? depth0.href : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"href","hash":{},"data":data}) : helper)))
    + "\">\n            <input id=\"id_\" name=\"id_\" type=\"hidden\" value=\"action\">\n            <input id=\"type_\" name=\"type_\" type=\"hidden\" value=\"paid\">\n            <input id=\"csrf_token\" name=\"csrf_token\" type=\"hidden\" value=\""
    + alias3(((helper = (helper = helpers.csrf || (depth0 != null ? depth0.csrf : depth0)) != null ? helper : alias1),(typeof helper === alias2 ? helper.call(depth0,{"name":"csrf","hash":{},"data":data}) : helper)))
    + "\">\n            <button class=\"btn btn-success btn-xs paid-btn\" type=\"submit\">"
    + alias3((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Mark Paid",{"name":"gettext","hash":{},"data":data}))
    + "</button>\n          </form>\n";
},"13":function(depth0,helpers,partials,data) {
    return "          <button class=\"btn btn-success btn-xs paid-btn\" type=\"submit\" disabled>"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"Mark Paid",{"name":"gettext","hash":{},"data":data}))
    + "</button>\n";
},"15":function(depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.compare || (depth0 && depth0.compare) || helpers.helperMissing).call(depth0,(depth0 != null ? depth0.type : depth0),"comment",{"name":"compare","hash":{"operator":"!=="},"fn":this.program(16, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"16":function(depth0,helpers,partials,data) {
    var stack1, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "    <tr class=\""
    + alias2((helpers.status_color || (depth0 && depth0.status_color) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),{"name":"status_color","hash":{},"data":data}))
    + "\">\n      <td>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,(helpers.capitalize || (depth0 && depth0.capitalize) || alias1).call(depth0,(depth0 != null ? depth0.type : depth0),{"name":"capitalize","hash":{},"data":data}),{"name":"gettext","hash":{},"data":data}))
    + "</td>\n      <td>"
    + alias2(this.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</td>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.note : depth0),{"name":"if","hash":{},"fn":this.program(17, data, 0),"inverse":this.program(19, data, 0),"data":data})) != null ? stack1 : "")
    + "      <td>"
    + alias2((helpers.datefmt || (depth0 && depth0.datefmt) || alias1).call(depth0,(depth0 != null ? depth0.timestamp : depth0),{"name":"datefmt","hash":{},"data":data}))
    + "</td>\n    </tr>\n";
},"17":function(depth0,helpers,partials,data) {
    var helper, alias1=helpers.helperMissing, alias2=this.escapeExpression;

  return "      <td>\n        <a class=\"small-popover\" data-toggle=\"popover\" data-trigger=\"focus\" data-content=\""
    + alias2(((helper = (helper = helpers.note || (depth0 != null ? depth0.note : depth0)) != null ? helper : alias1),(typeof helper === "function" ? helper.call(depth0,{"name":"note","hash":{},"data":data}) : helper)))
    + "\" href=\"#\" tabindex=\"0\">"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Comment",{"name":"gettext","hash":{},"data":data}))
    + "</a></td>\n      </td>\n";
},"19":function(depth0,helpers,partials,data) {
    return "      <td></td>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1, helper, alias1=helpers.helperMissing, alias2=this.escapeExpression, alias3="function", alias4=this.lambda;

  return "<div class=\"panel panel-"
    + alias2((helpers.status_color || (depth0 && depth0.status_color) || alias1).call(depth0,(depth0 != null ? depth0.status : depth0),{"name":"status_color","hash":{},"data":data}))
    + "\" data-request-id=\""
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" id=\"request-"
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"panel-heading\">\n    <span class=\"panel-title\">\n      <a class=\"request-href\" href=\""
    + alias2(((helper = (helper = helpers.href || (depth0 != null ? depth0.href : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"href","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Request #%(request_id)s",{"name":"gettext","hash":{"request_id":(depth0 != null ? depth0.id : depth0)},"data":data}))
    + "</a>\n    </span>\n  </div>\n  <div class=\"panel-body\">\n    <div class=\"row\">\n      <dl class=\"col-sm-6 dl-horizontal\">\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"External Lossmail",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd><a href=\""
    + alias2(((helper = (helper = helpers.killmail_url || (depth0 != null ? depth0.killmail_url : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"killmail_url","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">"
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + " <i class=\"fa fa-external-link\"></i></a></dd>\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Details",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>\n          <a class=\"small-popover null-link details\" data-toggle=\"popover\" data-trigger=\"focus\" data-content=\""
    + alias2((helpers.urlize || (depth0 && depth0.urlize) || alias1).call(depth0,(depth0 != null ? depth0.details : depth0),{"name":"urlize","hash":{"limit":15},"data":data}))
    + "\" data-container=\"body\" href=\"#\" tabindex=\"0\">"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Details",{"name":"gettext","hash":{},"data":data}))
    + "</a>\n        </dd>\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Ship",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>"
    + alias2((helpers.transformed || (depth0 && depth0.transformed) || alias1).call(depth0,depth0,"ship",{"name":"transformed","hash":{},"data":data}))
    + "</dd>\n"
    + ((stack1 = helpers["if"].call(depth0,(depth0 != null ? depth0.modifiers : depth0),{"name":"if","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Actions",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd><a class=\"null-link\" data-toggle=\"collapse\" data-parent=\"#requests\" href=\"#\" data-target=\"#actions-"
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.numberfmt || (depth0 && depth0.numberfmt) || alias1).call(depth0,(helpers.count || (depth0 && depth0.count) || alias1).call(depth0,(depth0 != null ? depth0.actions : depth0),{"name":"count","hash":{},"data":data}),{"name":"numberfmt","hash":{},"data":data}))
    + "</a></dd>\n      </dl>\n      <dl class=\"col-sm-6 dl-horizontal spaced\">\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Division",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>"
    + alias2(alias4(((stack1 = (depth0 != null ? depth0.division : depth0)) != null ? stack1.name : stack1), depth0))
    + "</dd>\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Pilot",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>\n          <button class=\"btn btn-default btn-xs copy-btn\" data-clipboard-text=\""
    + alias2(alias4(((stack1 = (depth0 != null ? depth0.pilot : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" data-toggle=\"tooltip\" title=\""
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Copy %(copy_label)s to clipboard",{"name":"gettext","hash":{"copy_label":((stack1 = (depth0 != null ? depth0.pilot : depth0)) != null ? stack1.name : stack1)},"data":data}))
    + "\">"
    + alias2(alias4(((stack1 = (depth0 != null ? depth0.pilot : depth0)) != null ? stack1.name : stack1), depth0))
    + "</button>\n        </dd>\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Payout",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>\n          <button class=\"btn btn-default btn-xs copy-btn\" data-clipboard-text=\""
    + alias2((helpers.currencyfmt || (depth0 && depth0.currencyfmt) || alias1).call(depth0,(depth0 != null ? depth0.payout : depth0),{"name":"currencyfmt","hash":{},"data":data}))
    + "\" data-toggle=\"tooltip\" title=\""
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Copy %(copy_label)s to clipboard",{"name":"gettext","hash":{"copy_label":(helpers.currencyfmt || (depth0 && depth0.currencyfmt) || alias1).call(depth0,(depth0 != null ? depth0.payout : depth0),{"name":"currencyfmt","hash":{},"data":data})},"data":data}))
    + "\">"
    + alias2((helpers.currencyfmt || (depth0 && depth0.currencyfmt) || alias1).call(depth0,(depth0 != null ? depth0.payout : depth0),{"name":"currencyfmt","hash":{},"data":data}))
    + "</button>\n        </dd>\n        <dt>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Reason",{"name":"gettext","hash":{},"data":data}))
    + "</dt>\n        <dd>\n          <button class=\"btn btn-default btn-xs copy-btn\" data-clipboard-text=\"Payment for "
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-toggle=\"tooltip\" title=\""
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Copy %(copy_label)s to clipboard",{"name":"gettext","hash":{"copy_label":(helpers["Payment for {{id}}"] || (depth0 && depth0["Payment for {{id}}"]) || alias1).call(depth0,{"name":"Payment for {{id}}","hash":{},"data":data})},"data":data}))
    + "\">"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Payment for %(request_id)s",{"name":"gettext","hash":{"request_id":(depth0 != null ? depth0.id : depth0)},"data":data}))
    + "</button>\n        </dd>\n        <dt></dt>\n        <dd>\n"
    + ((stack1 = (helpers.compare || (depth0 && depth0.compare) || alias1).call(depth0,(depth0 != null ? depth0.status : depth0),"approved",{"name":"compare","hash":{},"fn":this.program(11, data, 0),"inverse":this.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "        </dd>\n      </dl>\n    </div>\n  </div>\n  <table class=\"table collapse\" id=\"actions-"
    + alias2(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias1),(typeof helper === alias3 ? helper.call(depth0,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <tr>\n      <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Actions",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n      <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"User",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n      <th></th>\n      <th>"
    + alias2((helpers.gettext || (depth0 && depth0.gettext) || alias1).call(depth0,"Timestamp",{"name":"gettext","hash":{},"data":data}))
    + "</th>\n    </tr>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.actions : depth0),{"name":"each","hash":{},"fn":this.program(15, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  </table>\n</div>\n";
},"useData":true});
