// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(depth0,helpers,partials,data) {
    var stack1, alias1=this.escapeExpression, alias2=helpers.helperMissing;

  return ((stack1 = helpers["if"].call(depth0,(data && data.first),{"name":"if","hash":{},"fn":this.program(2, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "")
    + "  <li>\n    <a href=\"#\" id=\""
    + alias1(this.lambda(depth0, depth0))
    + "\">"
    + alias1((helpers.gettext || (depth0 && depth0.gettext) || alias2).call(depth0,(helpers.capitalize || (depth0 && depth0.capitalize) || alias2).call(depth0,depth0,{"name":"capitalize","hash":{},"data":data}),{"name":"gettext","hash":{},"data":data}))
    + "</a>\n  </li>\n"
    + ((stack1 = helpers["if"].call(depth0,(data && data.last),{"name":"if","hash":{},"fn":this.program(4, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"2":function(depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\">\n  <span class=\"caret\"></span>\n  <span class=\"sr-only\">"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"Toggle Dropdown",{"name":"gettext","hash":{},"data":data}))
    + "</span>\n</button>\n<ul class=\"dropdown-menu\" role=\"menu\">\n";
},"4":function(depth0,helpers,partials,data) {
    return "</ul>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
    var stack1;

  return "<button type=\"submit\" id=\"comment\" class=\"btn btn-default\">"
    + this.escapeExpression((helpers.gettext || (depth0 && depth0.gettext) || helpers.helperMissing).call(depth0,"Comment",{"name":"gettext","hash":{},"data":data}))
    + "</button>\n"
    + ((stack1 = helpers.each.call(depth0,(depth0 != null ? depth0.valid_actions : depth0),{"name":"each","hash":{},"fn":this.program(1, data, 0),"inverse":this.noop,"data":data})) != null ? stack1 : "");
},"useData":true});
